import { PUBLIC_NODE_ENV } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
  dsn: 'https://af3631e39f57fee0bbda85c9eb81ea4a@o4507697980702720.ingest.de.sentry.io/4507724432998480',
  enabled: PUBLIC_NODE_ENV !== 'development',
  environment: PUBLIC_NODE_ENV,
  tracesSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error']
    })
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const myErrorHandler: HandleClientError = ({ error, event }) => {
  console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
